import React from "react";
import { GatsbyGraphQLType, graphql } from "gatsby";
import {
  ContentfulRichTextGatsbyReference,
  renderRichText,
  RenderRichTextData,
} from "gatsby-source-contentful/rich-text";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

import MainLayout from "./MainLayout";
import SEO from "../atoms/Seo";
import { Box, makeStyles, Typography } from "@material-ui/core";
import ArticleList from "../atoms/articles/ArticleList";
import ContentfulOptions, { getOptions } from "./Options";

const useStyles = makeStyles((theme) => ({
  root: {},
  date: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
}));

interface IBlogData {
  data: {
    post: {
      title: string;
      body: RenderRichTextData<ContentfulRichTextGatsbyReference>;
      excerpt: string;
      updatedAt: string;
    };
  };
}

const BlogTemplate: React.FC<{ data: GatsbyTypes.getPostQuery }> = ({
  data,
}) => {
  const classes = useStyles();
  const { post } = data;

  const options = getOptions(post?.body?.references);

  let node = undefined;
  if (post?.createdAt) {
    const lastUpdatedDate = post?.updatedAt ? post.updatedAt : post.createdAt;
    node = {
      first_publication_date: new Date(post.createdAt),
      last_publication_date: new Date(lastUpdatedDate),
    };
  }

  return (
    <MainLayout>
      <SEO
        title={post?.title}
        description={post?.excerpt}
        article={true}
        node={node}
      />
      <Typography component="div">
        <section>
          <Box component="h2">{post?.title}</Box>

          <Box
            className={classes.date}
            fontStyle="italic"
            fontSize="caption.fontSize"
            // fontWeight="fontWeightLight"
          >
            {node?.last_publication_date ? (
              <>
                <span>Actualizat în </span>
                <span id="dateModified">
                  {node?.last_publication_date?.toLocaleDateString("ro", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
                </span>
              </>
            ) : (
              ""
            )}
          </Box>
          <Typography align="justify" component="div">
            <article>{renderRichText(post?.body as any, options)}</article>
          </Typography>
        </section>
      </Typography>
    </MainLayout>
  );
};

export const query = graphql`
  query getPost($slug: String!) {
    post: contentfulBlogPost(slug: { eq: $slug }) {
      id
      title
      excerpt
      createdAt
      updatedAt
      body {
        raw
        references {
          ...assetFields
        }
      }
    }
  }

  fragment assetFields on ContentfulAsset {
    contentful_id
    title
    description
    __typename
    fixed(width: 1600) {
      ...fixedFields
    }
    gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
  }

  fragment fixedFields on ContentfulFixed {
    width
    height
    src
    srcSet
  }
`;

export default BlogTemplate;
